* {
  box-sizing: border-box;
  margin: 0;
}

:root {
  --top-color: white;
  --text-color: rgb(94, 94, 94);
  --navbar-color: rgb(216, 211, 211);
  --theme-color: #872156;
}

html {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.top-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100vw;
  height: 80px;
  background-color: var(--top-color);
  z-index: 11;
}

.top-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80vw;
  list-style: none;
  align-items: center;
  padding-right: 40px;
  height: 80px;
}

.top-links a {
  text-decoration: none;
  color: var(--text-color);
  font-size: 1rem;
}

.top-address {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 310px;
}

.top-address p {
  color: var(--text-color);
  margin: 0;
}

.addresses {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}

a:hover {
  color: var(--theme-color);
}

.top-logo {
  font-size: 4rem;
  height: 60px;
  margin-left: 9px;
  margin-top: 9px;
}

.top-icon {
  width: 1.5rem;
  height: 1.5rem;
  align-items: center;
  margin-right: 15px;
  transform: translateY(-2px);
  color: var(--theme-color);
}

.navBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  width: 100vw;
  background-color: var(--navbar-color);
  transition: 300ms;
  position: sticky;
  z-index: 10;
}

.nav-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 86vw;
  list-style: none;
  align-items: center;
  margin-right: 160px;
  z-index: 10;
}

.nav-links li {
  display: flex;
  height: 40px;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
  z-index: 10;
}

#longLink {
  min-width: 240px;
}

.nav-links a {
  text-decoration: none;
  color: var(--text-color);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.nav-links li:hover {
  background-color: var(--theme-color);
}

.nav-links li:hover a {
  color: white;
}

.languages {
  display: flex;
  position: absolute;
  justify-content: space-between;
  align-items: center;
  width: 120px;
  height: 37px;
  right: 40px;
  z-index: 15;
}

.navBar.show .languages {
  right: 50%;
  bottom: 0;
  transform: translateX(50%);
  display: flex;
}

.link-burger {
  display: none;
  position: absolute;
  content: '';
  width: 40px;
  height: 40px;
  right: 40px;
  top: 18px;
  align-items: center;
}

.link-burger:hover,
.lang:hover {
  cursor: pointer;
  opacity: 50%;
}

.link-burger span {
  display: flex;
  position: absolute;
  content: '';
  width: 100%;
  height: 5px;
  background-color: var(--text-color);
  border-radius: 10px;
}

.link-burger .top {
  transform: translateY(-10px);
}

.link-burger .bottom {
  transform: translateY(10px);
}

.navBar.show {
  height: 160px;
  transition: 300ms;
  border-bottom: 2px solid gray;
  top: 80px;
  z-index: 10;
}

.nav-links.show {
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  z-index: 10;
  width: 100vw;
  transform: translateY(0px);
}

.nav-links.show a {
  border-bottom: 1px gray solid;
}

/* Quick fix for bootstrap messing up navbar links */
.nav-links {
  transform: translateY(8px);
}

@media screen and (max-width: 800px) {
  .top-links,
  .languages,
  .nav-links {
    display: none;
  }

  .nav-links,
  .navBar,
  .top-bar {
    position: fixed;
  }

  .top-bar {
    top: 0;
    border-bottom: 2px solid gray;
  }
  
  .link-burger {
    display: flex;
  }

  .navBar {
    height: 0;
    transition: 300ms;
  }

  .background-image {
    height: 300px;
  }
}

@media screen and (max-width: 900px) {
  .top-address {
    display: none;
  }
}